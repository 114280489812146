<template>
  <div class="container">
    <div class="page-content">
      <div class="channel-panel">
        <input-select
          title="ช่องทางการสั่งซื้อ"
          text="ช่องทางการสั่งซื้อ"
          name="day"
          placeholder="ช่องทางการสั่งซื้อ"
          valueField="value"
          textField="text"
          class="f-regular"
        />
        <input-select
          title="ชื่อร้านค้า"
          text="ชื่อร้านค้า"
          name="day"
          placeholder="ชื่อร้านค้า"
          valueField="value"
          textField="text"
          class="f-regular"
        />
        <input-text
          textFloat="หมายเลขสั่งซื้อ (Order No.)"
          placeholder="หมายเลขสั่งซื้อ (Order No.)"
          type="text"
          name="first_name_th"
          isRequired
          class="f-regular"
        />
        <input-text
          textFloat="หมายเลขจัดส่ง (Tracking ID)"
          placeholder="หมายเลขจัดส่ง (Tracking ID)"
          type="text"
          name="first_name_th"
          isRequired
          class="f-regular"
        />
      </div>
      <div class="button-panel">
        <b-button
          class="w-100"
          :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
          >เพิ่มช่องทางเชื่อมต่อ</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  width: 100%;
  height: 100%;
  img {
    width: 50px;
    height: 50px;
    // border-radius: 50%;
  }
  td {
    padding: 5px 0px;
  }
}
.button-panel {
  position: absolute;

  width: calc(100% - 2rem);
  left: 0;
  margin: 1rem;
  bottom: 0;
}
.channel-panel {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
</style>
